<template>
  <div>
    <div class="d-flex">
      <v-avatar color="grey lighten-1">
        <v-icon dark>
          mdi-account-circle
        </v-icon>
      </v-avatar>
      <h1 class="ml-2">Contact for {{ contact.business_name }}</h1>
    </div>
    <div class="mt-4 pa-4">
      <div class="text-subtitle-2 caption">Details</div>
      <v-list>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>
              mdi-calendar
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <!-- <v-list-item-title>{{ contact.date_created || moment }}</v-list-item-title> -->
            <v-list-item-title>{{ date }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset></v-divider>
         <v-list-item>
          <v-list-item-icon>
            <v-icon>
              mdi-face-agent
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ contact.outreach_specialist_first_name }} {{ contact.outreach_specialist_last_name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset></v-divider>
      </v-list>
      <div class="text-subtitle-2 caption">Business</div>
      <v-list>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>
              mdi-domain
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ contact.business_name }}</v-list-item-title>

          </v-list-item-content>
        </v-list-item>
        <v-divider inset></v-divider>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>
              mdi-account
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ contact.business_owner }}</v-list-item-title>

          </v-list-item-content>
        </v-list-item>
        <v-divider inset></v-divider>
      </v-list>

      <div class="text-subtitle-2 caption">Referral source</div>
      <v-list>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>
              mdi-account-multiple
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-if="contact.referral_source !== ''">{{ contact.referral_source }}</v-list-item-title>
            <v-list-item-title v-else>N/A</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset></v-divider>
      </v-list>

      <div class="text-subtitle-2 caption">Contact source</div>
      <v-list>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>
              mdi-phone
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-if="contact.contact_source">{{ contact.contact_source }}</v-list-item-title>
            <v-list-item-title v-else>N/A</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset></v-divider>
      </v-list>
      <template v-if="referralType">
        <div class="text-subtitle-2 caption">Referral type</div>
        <v-list>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>
                mdi-file-document
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-if="referralType">{{ referralType }}</v-list-item-title>
              <v-list-item-title v-else>N/A</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider inset></v-divider>
        </v-list>
        <div class="text-subtitle-2 caption">Program name</div>
        <v-list>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>
                mdi-file-document
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-if="contact.program_name">{{ contact.program_name }}</v-list-item-title>
              <v-list-item-title v-else>N/A</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider inset></v-divider>
        </v-list>
      </template>

      <div class="text-subtitle-2 caption">Reason for inquiry</div>
      <v-list>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>
              mdi-help
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title  v-if="contact.reason_for_inquiry">{{ contact.reason_for_inquiry }}</v-list-item-title>
            <v-list-item-title v-else>N/A</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset></v-divider>
      </v-list>
      <div class="text-subtitle-2 caption">Notes</div>
      <v-list>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>
              mdi-pen
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title  v-if="contact.notes">{{ contact.notes }}</v-list-item-title>
            <v-list-item-title v-else>N/A</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset></v-divider>
      </v-list>
    </div>
    <DeleteContact
      title="Delete Contact"
      :message="`Are you sure you want to delete this contact?`"
      icon="delete"
      action="Delete"
      @submit="deleteItem"/>
    <!-- <v-btn
      :to="{ name: 'addcontact' }"
      class="add-btn"
      color="primary"
      large dark absolute bottom right
    >
      <v-icon class="mr-2">mdi-message-text</v-icon> New Contact
    </v-btn> -->
  </div>
</template>
<script>
  import { mapActions } from 'vuex';
  import DeleteContact from '@/components/ConfirmModal.vue';
  import moment from 'moment';

  export default {
    name: 'ViewContact',
    components: {
      DeleteContact
    },
    data() {
      return {
        contact: {
          id: '',
          date:  '',
          business: '',
          contact_source: '',
          outreach_specialist:  '',
          referralto:  '',
          referral_source:  '',
          reason_for_inquiry:  '',
          notes:  '',
          business_name:  '',
          business_owner:  '',
          outreach_specialist_first_name:  '',
          outreach_specialist_last_name:  '',
          referral_type:  '',
          program_name: ''
        }
      }
    },
    computed: {
      businesses() {
        return this.$store.getters.businesses
      },
      specialists() {
        return this.$store.getters.specialists
      },
      date() {
        let dateCreated = new Date(this.contact.date_created);
        return moment(dateCreated).format('MMMM DD, YYYY')
      },
      referralType() {
        return this.contact.referral_type > 0 ? this.contact.referral_type == 1 ? 'Internal' : 'External' : false;
      }
    },
    created() {
      //this.getCurrentContact()
      this.getContact({ id: Number(this.$route.params.id) })
      .then((response) => {
      console.log('response', response)
        this.contact = Object.assign({}, this.contact, response.data )
      }, (error) => {
        console.log('error', error)
      });
    },
    methods: {
      ...mapActions(['getContact', 'deleteContact']),
      // getCurrentContact(id) {
      //   this.getBusiness({ id: id })
      //   .then((response) => {
      //   console.log('response', response)
      //     this.business = Object.assign({}, this.business, response.data )
      //   }, (error) => {
      //     console.log('error', error)
      //   });
      // },
      deleteItem() {
        this.deleteContact({id: this.contact.id})
      },
      getBusinessInfo(id) {
        return this.businesses ? this.businesses.find(business => business.id == id) : {};
      },
      getSpecialistInfo(id) {
        return this.specialists ? this.specialists.find(specialist => specialist.id == id) : {};
      },
    },
    filter: {
      moment: function (date) {
        return moment(date).format('MM-DD-YYYY');
      }
    }
  }
</script>
<style lang="scss" scoped>
  ::v-deep .v-btn.error {
    position: absolute;
    bottom: 16px;
    left: 16px;
  }
</style>
